<template>
    <DropdownMenuTrigger v-bind="forwardedProps">
        <slot />
    </DropdownMenuTrigger>
</template>

<script setup lang="ts">
    import { DropdownMenuTrigger, type DropdownMenuTriggerProps, useForwardProps } from 'radix-vue';

    const props = defineProps<DropdownMenuTriggerProps>();

    const forwardedProps = useForwardProps(props);
</script>
