<template>
    <DropdownMenuPortal>
        <DropdownMenuContent
            v-bind="forwarded"
            :class="cn(
                'z-50 min-w-40 overflow-hidden rounded-md ring-1 ring-gray-200 bg-white p-1 text-gray-950 shadow-md',
                'dark:ring-gray-800 dark:bg-gray-950 dark:text-gray-50',
                'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95',
                'data-[state=closed]:animate-out data-[state=closed]:fade-out-0  data-[state=closed]:zoom-out-95',
                'data-[side=bottom]:slide-in-from-top-2',
                'data-[side=left]:slide-in-from-right-2',
                'data-[side=right]:slide-in-from-left-2',
                'data-[side=top]:slide-in-from-bottom-2',
                !autoWidth && 'w-[--radix-dropdown-menu-trigger-width]',
                props.class,
            )"
        >
            <slot />
        </DropdownMenuContent>
    </DropdownMenuPortal>
</template>

<script setup lang="ts">
    import { type HTMLAttributes, computed } from 'vue';
    import {
        DropdownMenuContent,
        type DropdownMenuContentEmits,
        type DropdownMenuContentProps,
        DropdownMenuPortal,
        useForwardPropsEmits,
    } from 'radix-vue';
    import cn from '@aspect/shared/utils/cn.ts';

    const props = defineProps<DropdownMenuContentProps & {
        class?: HTMLAttributes['class'],
        autoWidth?: boolean,
    }>();
    const emits = defineEmits<DropdownMenuContentEmits>();

    const delegatedProps = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { class: _, ...delegated } = props;

        return delegated;
    });

    const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>
