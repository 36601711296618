<template>
    <DropdownMenu>
        <DropdownMenuTrigger :as-child="true">
            <slot name="trigger" />
        </DropdownMenuTrigger>
        <DropdownMenuContent v-bind="forwardedProps">
            <div v-if="$slots.header" class="-mx-1 -mt-1 mb-1 flex flex-col justify-center border-b border-gray-200 bg-gray-100 p-4">
                <slot name="header" />
            </div>
            <slot name="content" />
        </DropdownMenuContent>
    </DropdownMenu>
</template>

<script lang="ts" setup>
    import { useForwardProps } from 'radix-vue';

    import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './ui/dropdown-menu';

    import type { HTMLAttributes } from 'vue';
    import type { DropdownMenuContentProps } from 'radix-vue';

    const props = withDefaults(defineProps<DropdownMenuContentProps & {
        class?: HTMLAttributes['class'],
        autoWidth?: boolean,
    }>(), {
        autoWidth: true,
        align: 'start',
        sideOffset: 8,
    });

    const forwardedProps = useForwardProps(props);
</script>
