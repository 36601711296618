<template>
    <DropdownMenuItem
        v-bind="forwardedProps"
        :class="cn(
            'relative flex cursor-default select-none items-center rounded p-2 gap-2 text-sm outline-none transition-colors',
            !props.disabled && 'focus:bg-gray-100 focus:text-gray-900',
            !props.disabled && 'dark:focus:bg-gray-800 dark:focus:text-gray-50',
            'data-[disabled]:opacity-50 data-[disabled]:active:pointer-events-none',
            inset && 'pl-8',
            props.class,
        )"
    >
        <slot />
    </DropdownMenuItem>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { DropdownMenuItem, useForwardProps } from 'radix-vue';

    import cn from '@aspect/shared/utils/cn.ts';

    import type { HTMLAttributes } from 'vue';
    import type { DropdownMenuItemProps } from 'radix-vue';

    const props = defineProps<DropdownMenuItemProps & {
        class?: HTMLAttributes['class'],
        inset?: boolean,
    }>();

    const delegatedProps = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { class: _, ...delegated } = props;

        return delegated;
    });

    const forwardedProps = useForwardProps(delegatedProps);
</script>
